import { createContext, useContext, useState, useEffect, useRef } from 'react';
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { loginRequest, msalConfig } from '../azureConfig';

const AzureAuthContext = createContext();

export const useAuth = () => {
	return useContext(AzureAuthContext);
};

function AzureAuthProvider({ children }) {
	const instance = useRef(null);
	useEffect(() => {
		const checkUser = async () => {
			const msalInstance = new PublicClientApplication(msalConfig);
			setInitialized(true);

			console.log(msalInstance.getActiveAccount());

			if (msalInstance.getActiveAccount()) {
				const account = msalInstance.getActiveAccount();
				console.log('logged in', account);
				setAuthenticated(true);
				setUser(account);
			}

			// Default to using the first account if no account is active on page load
			if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
				// Account selection logic is app dependent. Adjust as needed for different use cases.
				msalInstance.setActiveAccount(msalInstance.getActiveAccount()[0]);
			}

			// Listen for sign-in event and set active account
			msalInstance.addEventCallback(event => {
				console.log('event', event);
				if (
					(event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) ||
					(event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS && event.payload.account)
				) {
					const { account } = event.payload;
					console.log('logged in', account);
					setAuthenticated(true);
					setUser(account);
					instance.current.setActiveAccount(account);
				}
				if (event.eventType === EventType.LOGOUT_END) {
					setAuthenticated(false);
					setUser(null);
				}
			});
			await msalInstance.initialize();
			instance.current = msalInstance;
		};

		checkUser();
	}, []);

	const [user, setUser] = useState(null);
	const [authenticated, setAuthenticated] = useState(false);
	const [initialized, setInitialized] = useState(false);

	const signIn = async () => {
		await instance.current.loginPopup(loginRequest);
	};

	const signOut = async () => {
		instance.current.logoutPopup();
	};

	const getAccessToken = async () => {
		const request = {
			scopes: ['user.read']
		};
		let response = null;

		try {
			response = await instance.current.acquireTokenSilent(request);
		} catch (error) {
			console.log(error);
		}

		return response?.accessToken;
	};

	return (
		<AzureAuthContext.Provider
			// eslint-disable-next-line react/jsx-no-constructed-context-values
			value={{
				user: {
					displayName: user?.username,
					role: user?.role ?? 'admin',
					isSuperadmin: false,
					tenant: user?.tenantId,
					email: user?.username,
					...user
				},
				isAuthenticated: authenticated,
				isInitialized: initialized,
				login: signIn,
				logout: signOut,
				getAccessToken
			}}
		>
			{children}
		</AzureAuthContext.Provider>
	);
}

export { AzureAuthContext, AzureAuthProvider };
