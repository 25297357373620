import {
	GET_SOURCES,
	SET_FILES_FOR_UPLOAD,
	TOGGLE_MODAL,
	SET_ACTIVE_FILE_INDEX,
	GET_AUTH_URL,
	GET_AUTH_TOKEN,
	REDEEM_AUTH_CODE,
	CLEAR_APP_AUTH,
	DELETE_TOKEN,
	TOGGLE_UPLOAD_TYPE,
	TOGGLE_EXTRACT_IMAGES,
	TOGGLE_CLASS_MODAL
} from '../actions/types';

export default function (state = {}, action) {
	switch (action.type) {
		case GET_SOURCES:
			return {
				...state,
				sources: action.payload
			};

		case SET_FILES_FOR_UPLOAD:
			return {
				...state,
				filesForUpload: action.payload,
				spreadsheetMode: Boolean(action.payload.find(file => file.type === 'text/csv'))
			};
		case TOGGLE_MODAL:
			return {
				...state,
				modalType: action.payload,
				progress: action.payload ? state.progress : {},
				filesForUpload: action.payload ? state.filesForUpload : [],
				activeIndex: null
			};
		case TOGGLE_CLASS_MODAL:
			return {
				...state,
				showClassModal: action.payload.isOpen,
				classModalId: action.payload.files?.[0]?.id,
				classModalFiles: action.payload.files
			};
		case TOGGLE_UPLOAD_TYPE:
			return {
				...state,
				uploadType: action.payload
			};
		case TOGGLE_EXTRACT_IMAGES:
			return {
				...state,
				extractImages: action.payload
			};
		case SET_ACTIVE_FILE_INDEX:
			return {
				...state,
				activeIndex: action.payload
			};
		case GET_AUTH_URL:
			return {
				...state,
				authUrl: action.payload
			};
		case GET_AUTH_TOKEN:
			return {
				...state,
				accessToken: action.payload?.data?.access_token,
				tenant: action.payload?.data?.tenant,
				user: action.payload?.data?.email,
				tokenStatus: action.payload?.status
			};
		case REDEEM_AUTH_CODE:
			return {
				...state,
				oauthStatus: action.payload
			};
		case CLEAR_APP_AUTH:
			return {
				...state,
				oauthStatus: null,
				accessToken: null,
				tokenStatus: null,
				authUrl: null,
				deletionTokenStatus: null
			};
		case DELETE_TOKEN:
			return {
				...state,
				deletionTokenStatus: action.payload
			};
		default:
			return state;
	}
}
