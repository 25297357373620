import { FETCH_MY_CONTACTS } from '../actions/types';

export default function (state = {}, action) {
	switch (action.type) {
		case FETCH_MY_CONTACTS: {
			const returnProps = {
				...state,
				myUsers: action.payload?.data,
				code: action.payload.status
			};
			return returnProps;
		}

		default:
			return state;
	}
}
