import { API } from 'aws-amplify';
import { FETCH_MY_CONTACTS } from './types';

import CONFIGURATION from '../../app_configuration';

export const fetchMyContacts = () => async dispatch => {
	const data = {
		response: true
	};

	let res = {};
	try {
		res = await API.get(CONFIGURATION.AWS_API_NAME, '/users', data);
	} catch (err) {
		res.status = 500;
	}

	dispatch({
		type: FETCH_MY_CONTACTS,
		payload: res
	});
};
