// Layout actions
export const SET_IS_MOBILE = 'SET_IS_MOBILE';
export const SET_CONNECTION_STATUS = 'setConnectionStatus';

// Actions associated with Profile screen
export const FETCH_MY_CONTACTS = 'FETCH_MY_CONTACTS';
export const AUTH_USER = 'AUTH_USER';

// actions associated with theme layout
export const SET_THEME_SETTINGS = 'SET_THEME_SETTINGS';

// chatbot actions
export const TOGGLE_CHATBOT_RIGHT_SIDEBAR = 'TOGGLE_CHATBOT_RIGHT_SIDEBAR';
export const CHATBOT_SESSION_ID = 'CHATBOT_SESSION_ID';
export const BOT_MESSAGE_LOADING = 'BOT_MESSAGE_LOADING';
export const SAVE_CHAT_HISTORY = 'SAVE_CHAT_HISTORY';
export const HISTORY_LOADING = 'HISTORY_LOADING';
export const SET_CHAT_SESSION = 'SET_CHAT_SESSION';
export const SET_IS_STREAMING = 'SET_IS_STREAMING';
export const FETCH_SETTINGS = 'FETCH_SETTINGS';
export const SET_AGENT_LIST = 'SET_AGENT_LIST';
export const GET_AGENT_SESSION_LIST_PENDING = 'GET_AGENT_SESSION_LIST_PENDING';
export const GET_AGENT_SESSION_LIST_SUCCESS = 'GET_AGENT_SESSION_LIST_SUCCESS';
export const GET_AGENT_SESSION_LIST_ERROR = 'GET_AGENT_SESSION_LIST_ERROR';
export const CREATE_AGENT_SESSION_PENDING = 'CREATE_AGENT_SESSION_PENDING';
export const CREATE_AGENT_SESSION_ERROR = 'CREATE_AGENT_SESSION_ERROR';
export const CREATE_AGENT_SESSION_SUCCESS = 'CREATE_AGENT_SESSION_SUCCESS';
export const DEACTIVATE_AGENT_SESSION = 'DEACTIVE_AGENT_SESSION';
export const RENAME_CONVERSATION_THREAD = 'RENAME_CONVERSATION_THREAD';
export const RENAME_CONVERSATION_ERROR = 'RENAME_CONVERSATION_ERROR';
export const RENAME_CONVERSATION_PENDING = 'RENAME_CONVERSATION_PENDING';
export const CONVERT_TEXT_TO_AUDIO = 'CONVERT_TEXT_TO_AUDIO';
export const SET_SPEECH_MODE = 'SET_SPEECH_MODE';
export const SET_ACTIVE_SPEECH_BUBBLE = 'SET_ACTIVE_SPEECH_BUBBLE';
export const UPDATE_AGENT_SESSION_NAME = 'UPDATE_AGENT_SESSION_LIST_NAME';

// common actions
export const SET_ERROR = 'SET_ERROR';

// superadmin actions
export const GET_TOOLS = 'GET_TOOLS';
export const GET_TENANTS = 'GET_TENANTS';
export const GET_USERS = 'GET_USERS';
export const GET_ALL_AGENTS = 'GET_ALL_AGENTS';
export const GET_AGENTS = 'GET_AGENTS';
export const GET_AGENT_TYPES = 'GET_AGENT_TYPES';
export const GET_MODEL_TYPES = 'GET_MODEL_TYPES';
export const CREATE_AGENT = 'CREATE_AGENT';
export const CLEAR_STATUS = 'CLEAR_STATUS';
export const GET_AGENT_BY_ID = 'GET_AGENT_BY_ID';
export const CLEAR_AGENT = 'CLEAR_AGENT';
export const UPDATE_AGENT = 'UPDATE_AGENT';
export const DELETE_AGENT = 'DELETE_AGENT';
export const GET_CONVERSATION_LIST = 'GET_CONVERSATION_LIST';
export const RESET_CONVERSATION_LIST = 'RESET_CONVERSATION_LIST';
export const GET_MESSAGE_HISTORY = 'GET_MESSAGE_HISTORY';
export const APPEND_MESSAGE_HISTORY = 'APPEND_MESSAGE_HISTORY';
export const APPEND_CONVERSATION_LIST = 'APPEND_CONVERSATION_LIST';
export const POST_CRITERIA_FETCH = 'POST_CRITERIA_FETCH';
export const POST_CRITERIA_EVALUATION = 'POST_CRITERIA_EVALUATION';
export const POST_PAIRWISE_CRITERIA_EVALUATION = 'POST_PAIRWISE_CRITERIA_EVALUATION';
export const GET_CRITERIA = 'GET_CRITERIA';
export const GET_PAIRWISE_CRITERIA = 'GET_PAIRWISE_CRITERIA';
export const POST_CRITERIA = 'POST_CRITERIA';
export const POST_PAIRWISE_CRITERIA = 'POST_PAIRWISE_CRITERIA';
export const DELETE_CRITERIA = 'DELETE_CRITERIA';
export const DELETE_PAIRWISE_CRITERIA = 'DELETE_PAIRWISE_CRITERIA';
export const POST_EVALUATE_CUSTOM = 'POST_EVALUATE_CUSTOM';
export const POST_FINETUNE_MODEL = 'POST_FINETUNE_MODEL';
export const GET_FINETUNED_MODELS = 'GET_FINETUNED_MODELS';
export const POST_FEEDBACK_EVALUATION = 'POST_FEEDBACK_EVALUATION';
export const GET_CURRENT_SYSTEM_PROMPT = 'GET_CURRENT_SYSTEM_PROMPT';
export const POST_RESOLVE_FEEDBACK = 'POST_RESOLVE_FEEDBACK';
export const POST_SHORTEN_SYSTEM_PROMPT = 'POST_SHORTEN_SYSTEM_PROMPT';
export const POST_DELETE_FINETUNE = 'POST_DELETE_FINETUNE';
export const SET_TESTING_EVALUATION_CHANGED = 'SET_TESTING_EVALUATION_CHANGED';
export const POST_ADD_GUARDRAIL = 'POST_ADD_GUARDRAIL';
export const GET_GUARDRAILS = 'GET_GUARDRAILS';
export const DELETE_GUARDRAIL = 'DELETE_GUARDRAIL';
export const PUT_UPDATE_GUARDRAIL = 'PUT_UPDATE_GUARDRAIL';
export const POST_TEST_GUARDRAIL = 'POST_TEST_GUARDRAIL';

// document manager actions
export const GET_SOURCES = 'GET_SOURCES';
export const GET_SOURCES_ERROR = 'GET_SOURCES_ERROR';
export const SET_FILES_FOR_UPLOAD = 'SET_FILES_FOR_UPLOAD';
export const TOGGLE_MODAL = 'TOGGLE_MODAL';
export const TOGGLE_LOADER = 'TOGGLE_LOADER';
export const SET_ACTIVE_FILE_INDEX = 'SET_ACTIVE_FILE_INDEX';
export const GET_AUTH_URL = 'GET_AUTH_URL';
export const GET_AUTH_TOKEN = 'GET_AUTH_TOKEN';
export const REDEEM_AUTH_CODE = 'REDEEM_AUTH_CODE';
export const CLEAR_APP_AUTH = 'CLEAR_APP_AUTH';
export const DELETE_TOKEN = 'DELETE_TOKEN';
export const TOGGLE_UPLOAD_TYPE = 'TOGGLE_UPLOAD_TYPE';
export const TOGGLE_EXTRACT_IMAGES = 'TOGGLE_EXTRACT_IMAGES';
export const TOGGLE_CLASS_MODAL = 'TOGGLE_CLASS_MODAL';
export const DOC_MANAGER_SLICE = 'documentManagerSlice';
export const CHATBOT_SLICE = 'chatbotSlice';
export const COMMON_SLICE = 'commonSlice';
export const ON_UPLOAD_ERROR = 'onUploadError';
export const ON_UPLOAD_SUCCESS = 'onUploadSuccess';
export const GET_ALL_MODEL_TYPES = 'getAllModelTypes';
export const SET_UPLOAD_DONE = 'SET_UPLOAD_DONE';
export const GET_MODEL_PROVIDERS = 'getModelProviders';
export const GET_MODEL_BY_ID = 'getModelById';
export const GET_MODEL_BY_ID_ERROR = 'getModelByIdError';
export const GET_MODEL_BY_ID_PENDING = 'getModelByIdPending';
