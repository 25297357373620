import { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import classNames from 'classnames';
import { APP_BAR_DESKTOP, APP_BAR_MOBILE } from 'constants';
import { withStyles } from '@mui/styles';
import { Drawer, CircularProgress } from '@mui/material';
import { experimentalStyled as styled } from '@mui/material/styles';
import { connect } from 'react-redux';
import { Sidebar, Topbar } from './components';
import styles from './styles';
import { LEFT_SIDEBAR_WIDTH } from '../../constants';

const MainStyle = styled('div')(({ theme }) => ({
	flexGrow: 1,
	minHeight: '100%',
	paddingTop: APP_BAR_DESKTOP,
	[theme.breakpoints.down('sm')]: {
		paddingTop: APP_BAR_MOBILE
	}
}));

function Dashboard({ classes, isMobile, history, isLoading }) {
	const [sidebarIsOpen, setSidebarIsOpen] = useState(!isMobile);

	useEffect(() => {
		if (isMobile) {
			setSidebarIsOpen(false);
		} else {
			setSidebarIsOpen(true);
		}
	}, [isMobile]);

	const handleClose = () => {
		setSidebarIsOpen(false);
	};

	const handleToggleOpen = () => {
		setSidebarIsOpen(!sidebarIsOpen);
	};

	const leftShift = sidebarIsOpen && !isMobile;
	const leftRightShift = leftShift && !isMobile;

	const renderSpinner = () => {
		if (isLoading) {
			return (
				<div className={classes.progressWrapper}>
					<CircularProgress />
				</div>
			);
		}

		return (
			<MainStyle>
				<Outlet />
			</MainStyle>
		);
	};

	return (
		<>
			<Topbar
				id="element"
				className={classNames(classes.topbar, {
					[classes.leftTopbarShift]: leftShift,
					[classes.leftRightTopbarShift]: leftRightShift
				})}
				isSidebarOpen={sidebarIsOpen}
				onToggleSidebar={handleToggleOpen}
				isMobile={isMobile}
				history={history}
			/>
			<Drawer
				anchor="left"
				classes={{ paper: classes.drawerPaper }}
				onClose={handleClose}
				open={sidebarIsOpen}
				variant="persistent"
				style={{ zIndex: 1100 }}
				PaperProps={{
					sx: {
						width: LEFT_SIDEBAR_WIDTH
					}
				}}
			>
				<Sidebar
					className={classes.sidebar}
					open={sidebarIsOpen}
					onToggleSidebar={handleToggleOpen}
				/>
			</Drawer>
			<main
				className={classNames(classes.content, {
					[classes.leftContentShift]: leftShift
				})}
			>
				{renderSpinner()}
			</main>
		</>
	);
}

function mapStateToProps(state) {
	return {
		isMobile: state.layout.isMobile
	};
}

export default connect(mapStateToProps)(withStyles(styles)(Dashboard));
