import { useState } from 'react';
// Material core components
import { makeStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'notistack';
import { fetchMyContacts } from '../../../redux/actions/profileScreenActions';
import { inviteUser } from '../../../services/api/userService';

const useStyles = makeStyles(theme => ({
	drawerPaper: {
		zIndex: 1190,
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		},
		[theme.breakpoints.up('md')]: {
			width: '50%'
		},
		[theme.breakpoints.up('lg')]: {
			width: '35%'
		}
	},
	radioGrid: {
		display: 'inline-flex'
	}
}));

function InviteUser({ openDialog, toggleInviteUser, handleClose }) {
	const classes = useStyles();
	const dispatch = useDispatch();

	const [email, setEmail] = useState('');
	const [userType, setUserType] = useState('user');
	const [isLoading, setIsLoading] = useState(false);

	const onSubmitForm = async event => {
		setIsLoading(true);
		event.preventDefault();
		setIsLoading(true);
		try {
			const response = await inviteUser(email, userType);
			if (response?.status === 201) {
				enqueueSnackbar(`User ${email} successfully invited`, { variant: 'info' });
				dispatch(fetchMyContacts());
				toggleInviteUser();
			} else {
				enqueueSnackbar(response?.data?.detail ?? 'Failed to invite user', {
					variant: 'error'
				});
			}
		} catch (error) {
			enqueueSnackbar(error?.response?.data?.detail ?? 'Failed to invite user', {
				variant: 'error'
			});
		}
		setIsLoading(false);
	};

	const handleChange = e => {
		setEmail(e.target.value);
	};

	const handleRadioChange = (event, value) => {
		setUserType(value);
	};

	return (
		<Dialog open={openDialog} onClose={handleClose} aria-labelledby="form-dialog-title">
			<DialogTitle>
				Invite User
				<IconButton
					aria-label="close"
					sx={{ position: 'absolute', right: 10, top: 10 }}
					onClick={toggleInviteUser}
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<form autoComplete="off" onSubmit={onSubmitForm}>
				<DialogContent dividers>
					<DialogContentText>
						Enter the email address in the form below and the user will receive an
						invitation.
					</DialogContentText>
					<br />
					<Grid container>
						<Grid item xs={12} sm={6}>
							<TextField
								required
								autoFocus
								id="email"
								type="email"
								data-test="email-input"
								label="Email Address:"
								value={email}
								onChange={handleChange}
								margin="normal"
								variant="outlined"
								style={{ width: '100%' }}
							/>
						</Grid>
						<Grid item xs={12} sm={6} className={classes.radioGrid}>
							<RadioGroup
								aria-label="type"
								name="userType"
								value={userType}
								onChange={handleRadioChange}
								row
								style={{ margin: 'auto' }}
							>
								<FormControlLabel
									value="user"
									control={<Radio color="primary" />}
									label="User"
									labelPlacement="top"
								/>
								<FormControlLabel
									value="admin"
									control={<Radio color="primary" />}
									label="Admin"
									labelPlacement="top"
								/>
							</RadioGroup>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<LoadingButton
						variant="outlined"
						loading={isLoading}
						data-test="send-invite-button"
						type="submit"
						color="primary"
					>
						Send Invitation
					</LoadingButton>
				</DialogActions>
			</form>
		</Dialog>
	);
}

export default InviteUser;
