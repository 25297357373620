import {
	TOGGLE_CHATBOT_RIGHT_SIDEBAR,
	CHATBOT_SESSION_ID,
	BOT_MESSAGE_LOADING,
	SAVE_CHAT_HISTORY,
	HISTORY_LOADING,
	SET_CHAT_SESSION,
	SET_IS_STREAMING,
	SET_AGENT_LIST,
	GET_AGENT_SESSION_LIST_PENDING,
	GET_AGENT_SESSION_LIST_SUCCESS,
	GET_AGENT_SESSION_LIST_ERROR,
	CREATE_AGENT_SESSION_SUCCESS,
	CREATE_AGENT_SESSION_PENDING,
	CREATE_AGENT_SESSION_ERROR,
	RENAME_CONVERSATION_ERROR,
	RENAME_CONVERSATION_PENDING,
	RENAME_CONVERSATION_THREAD,
	DEACTIVATE_AGENT_SESSION,
	SET_SPEECH_MODE,
	SET_ACTIVE_SPEECH_BUBBLE,
	UPDATE_AGENT_SESSION_NAME
} from '../actions/types';

export default function (state = { speechModeActive: {} }, action) {
	switch (action.type) {
		case SET_SPEECH_MODE:
			return {
				...state,
				speechModeActive: { ...state.speechModeActive, ...action.payload }
			};
		case SET_ACTIVE_SPEECH_BUBBLE:
			return {
				...state,
				activeSpeechBubble: action.payload
			};
		case RENAME_CONVERSATION_PENDING:
			return {
				...state,
				renamingSession: true,
				error: null
			};
		case RENAME_CONVERSATION_ERROR:
			return {
				...state,
				renamingSession: false,
				error: action.payload
			};
		case RENAME_CONVERSATION_THREAD:
			return {
				...state,
				renamingSession: false,
				sessionList: {
					...state.sessionList,
					[action.payload.agent.agent_id]: [
						...state.sessionList[action.payload.agent.agent_id].filter(
							item => item.conversation_id !== action.payload.conversation_id
						),
						action.payload
					]
				}
			};
		case CREATE_AGENT_SESSION_PENDING:
			return {
				...state,
				creatingSession: true
			};
		case CREATE_AGENT_SESSION_ERROR:
			return {
				...state,
				createdSession: null,
				creatingSession: false
			};
		case CREATE_AGENT_SESSION_SUCCESS:
			return {
				...state,
				sessionList: {
					...state.sessionList,
					[action.payload.agentId]: [
						action.payload.createdSession,
						...state.sessionList[action.payload.agentId]
					]
				},
				createdSession: action.payload.createdSession.conversation_id,
				creatingSession: false
			};
		case GET_AGENT_SESSION_LIST_PENDING:
			return {
				...state,
				sessionList: [],
				errorSession: null,
				loadingSessions: true
			};
		case GET_AGENT_SESSION_LIST_SUCCESS:
			return {
				...state,
				sessionList: {
					...state.sessionList,
					[action.payload.agentId]: action.payload.list
				},
				errorSession: null,
				loadingSessions: false
			};
		case GET_AGENT_SESSION_LIST_ERROR: {
			return {
				...state,
				sessionList: [],
				errorSession: action.payload,
				loadingSessions: false
			};
		}
		case UPDATE_AGENT_SESSION_NAME: {
			return {
				...state,
				sessionList: {
					...state.sessionList,
					[action.payload.agentId]: state.sessionList[action.payload.agentId].map(
						item => {
							return item.conversation_id === action.payload.conversationId
								? {
										...item,
										conversation_name: action.payload.newName,
										active_session: {
											...item.active_session,
											session_name: action.payload.newName
										}
								  }
								: item;
						}
					)
				}
			};
		}
		case TOGGLE_CHATBOT_RIGHT_SIDEBAR: {
			return {
				...state,
				rightSidebarOpen: Boolean(action.payload.data),
				data: action.payload.data
			};
		}
		case CHATBOT_SESSION_ID: {
			return {
				...state,
				...action.payload
			};
		}
		case BOT_MESSAGE_LOADING: {
			return {
				...state,
				isLoading: action.payload
			};
		}
		case SAVE_CHAT_HISTORY: {
			return {
				...state,
				history: action.payload,
				initialized: true
			};
		}
		case HISTORY_LOADING: {
			return {
				...state,
				isLoadingHistory: action.payload
			};
		}
		case SET_CHAT_SESSION: {
			return {
				...state,
				sessionId: action.payload
			};
		}
		case SET_IS_STREAMING: {
			return {
				...state,
				isStreaming: action.payload
			};
		}
		case SET_AGENT_LIST: {
			return {
				...state,
				agents: action.payload
			};
		}
		case DEACTIVATE_AGENT_SESSION: {
			return {
				...state,
				deletedSession: action.payload.historyId,
				sessionList: {
					...state.sessionList,
					[action.payload.agentId]: [
						...state.sessionList[action.payload.agentId].filter(
							item => item.history_id !== action.payload.historyId
						)
					]
				}
			};
		}
		default:
			return state;
	}
}
