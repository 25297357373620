import { API } from 'aws-amplify';
import CONFIGURATION from '../../app_configuration';

export const getUserProfile = async () => {
	const response = await API.get(CONFIGURATION.AWS_API_NAME, '/users/current-user', {
		response: true
	});
	return response;
};

export const getCurrentTenant = async () => {
	const response = API.get(CONFIGURATION.AWS_API_NAME, '/tenants/current-tenant', {
		response: true
	});
	return response;
};

export const createTenant = async () => {
	const response = await API.post(CONFIGURATION.AWS_API_NAME, '/tenants-create/create-tenant');
	return response;
};

export const inviteUser = async (email, role) => {
	const data = {
		body: { role, email },
		response: true
	};
	const response = await API.post(CONFIGURATION.AWS_API_NAME, '/users/invite', data);
	return response;
};

export const deleteUser = async users => {
	const data = {
		response: true,
		body: users
	};
	const response = await API.del(CONFIGURATION.AWS_API_NAME, `/users`, data);
	return response;
};

export const reinviteUser = async userEmail => {
	const requestData = {
		body: {
			email: userEmail
		},
		response: true
	};
	const response = await API.put(CONFIGURATION.AWS_API_NAME, `/users/invite`, requestData);
	return response;
};

export const changeUserRole = async (userId, newRole) => {
	const requestData = {
		body: {
			role: newRole,
			id: userId
		},
		response: true
	};
	const response = await API.patch(CONFIGURATION.AWS_API_NAME, `/users/user`, requestData);
	return response;
};
